import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 0, // Initial step
  steps: [
    { id: '01', name: 'Upload Files', status: 'current' },
    { id: '02', name: 'Choose Classifications', status: 'upcoming' },
    { id: '03', name: 'Start Query', status: 'upcoming' },
  ],
};

const fileQuerySilce = createSlice({
  name: 'fileQuerySteps',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.currentStep = action.payload;
      state.steps.forEach((step, index) => {
        if (index < action.payload) {
          step.status = 'complete';
        } else if (index === action.payload) {
          step.status = 'current';
        } else {
          step.status = 'upcoming';
        }
      });
    },
  },
});

export const { setStep } = fileQuerySilce.actions;
export default fileQuerySilce.reducer;
