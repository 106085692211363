import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ProtectedRoutes from './components/ProtectedRoutes'
import GeneralRoutes from './routes/GeneralRoutes';
import ApplicationRoutes from './routes/ApplicationRoutes';

import './App.css';

function App() {
  return (

      <Routes>
        <Route path="/*" element={<GeneralRoutes />} />

        {/* Routes that can be accessed if user is logged in */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard/*" element={<ApplicationRoutes />} />
        </Route>

      </Routes>

  );
};

export default App;
