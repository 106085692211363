import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'

import { TopSectionPolygon, BottomRightSectionPolygon } from '../../components/BackgroundPolygons';
import TextField from '../../components/TextField';
import AxiosInstance from "../../components/AxiosInstance"

import ilogo from "../../assets/i-logo.png";
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";
import React from "react";

const requirements = {
  password1: [
    'Must be at least 6 characters long',
    'Password must contain at least one uppercase letter.',
    'Password must contain at least one lowercase letter.',
    'Password must contain at least one digit.',
    'Password must contain at least one special character.'
  ],
  secretKey: [
    'Please enter your provided Secret Key.'
  ]
};

export default function Signup() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    watch,
    getValues
  } = useForm({
    defaultValues : {
    }
  });

  const navigate = useNavigate()

  const onSubmit = async (data) => {
    try {
      await AxiosInstance.post(`register/`, {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password1,
        secret_key: data.secretKey
      });
      localStorage.setItem('registrationSuccess', 'true');
      navigate(`/login`);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorData = error.response.data;

        // Handle field-specific errors
        if (errorData.email) {
          setError('email', {
            type: 'manual',
            message: errorData.email[0],
          });
        }
        if (errorData.password) {
          setError('password', {
            type: 'manual',
            message: errorData.password[0],
          });
        }
        if (errorData.secret_key) {
          setError('secretKey', {
            type: 'manual',
            message: errorData.secret_key[0],
          });
        }

        // Handle general errors (like group limit)
        if (errorData.non_field_errors || errorData.detail) {
          setError('root', {
            type: 'manual',
            message: errorData.non_field_errors ? errorData.non_field_errors[0] : errorData.detail,
          });
        }

      } else {
        setError('root', {
          type: 'manual',
          message: 'An unexpected error occurred.',
        });
        console.error('An unexpected error occurred:', error);
      }
    }
  };

  const validatePassword2 = (value) => {
    return value === watch('password1') || 'Passwords do not match.';
  };

  return (
    <>
      <div className="relative isolate overflow-clip">
        <TopSectionPolygon />
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <a href="/">
              <img
                alt="Your Company"
                src={ilogo}
                className="mx-auto h-10 w-auto"
              />
            </a>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
              Register an account with us
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" noValidate>

              {errors.root && (
                <div className="flex items-center p-4 mb-4 bg-red-100 rounded-lg">
                  <ExclamationCircleIcon aria-hidden="true" className="h-6 w-6 text-red-500 mr-3" />
                  <p id="root-error" className="text-sm text-red-600">
                    {errors.root.message}
                  </p>
                </div>
              )}

              <TextField
                id="firstName"
                label="First Name*"
                type="text"
                placeholder="First Name"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'First name is required.'
                }}
                getValues={getValues}
              />

              <TextField
                id="lastName"
                label="Last Name*"
                type="text"
                placeholder="Last Name"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'Last name is required.'
                }}
                getValues={getValues}
              />

              <TextField
                id="email"
                label="Email*"
                type="email"
                placeholder="you@example.com"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'Email is required.',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email address is invalid.'
                  }
                }}
                getValues={getValues}
              />

              <TextField
                id="password1"
                label="Password*"
                type="password"
                placeholder="Password"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'Password is required.',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters long.'
                  },
                  pattern: {
                    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).{6,}$/,
                    message: 'Password is not strong enough.'
                  }
                }}
                requirements={requirements.password1}
                getValues={getValues}
              />

              <TextField
                id="password2"
                label="Confirm Password*"
                type="password"
                placeholder="Confirm Password"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'Password confirmation is required.',
                  validate: validatePassword2
                }}
                getValues={getValues}
              />

              <TextField
                id="secretKey"
                label="Secret Key*"
                type="text"
                placeholder="Secret Key"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'Secret Key is required.'
                }}
                requirements={requirements.secretKey}
                getValues={getValues}
              />

              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex w-full justify-center rounded-md bg-gradient-primary px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-gradient-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                >
                  {isSubmitting? "Loading..." : "Register"}
                </button>
              </div>

            </form>

            <p className="mt-10 text-center text-sm text-gray-400">
              Already a member?{' '}
              <a href="/login" className="font-semibold leading-6 text-accent hover:text-accent-hover">
                Sign in
              </a>
            </p>
          </div>
        </div>
        <BottomRightSectionPolygon />
      </div>
    </>
  );
}