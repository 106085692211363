import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import Banner from '../components/Banner';
import NavBar from '../layouts/general/NavBar';
import Footer from '../layouts/general/Footer';

import Home from '../pages/general/Home/Home';
import Services from '../pages/general/Services/Services';
import MatrixGen from '../pages/general/Solutions/MatrixGen/MatrixGen';
import Pricing from '../pages/general/Pricing/Pricing'
import About from '../pages/general/About/About';
import Login from '../pages/login-signup/Login';
import Signup from '../pages/login-signup/Signup';
import PasswordResetRequest from '../pages/login-signup/PasswordResetRequest';
import PasswordReset from '../pages/login-signup/PasswordReset';

import ErrorPage from '../components/ErrorPage';

const GeneralRoutes = () => {
  const location = useLocation();

  const hiddenPaths = ["/login", "/sign-up", "/reset-password"];
  const hideNavBarAndFooter = hiddenPaths.some(path => location.pathname.startsWith(path));

  return (
    <div className="bg-gray-950">
      {location.pathname === "/" && <Banner/>}

      {!hideNavBarAndFooter && <NavBar/>}

      <div className={hideNavBarAndFooter ? "" : (location.pathname === "/" ?
          "mt-20" :
          "relative isolate overflow-hidden px-6 pt-32 sm:pt-40 pb-16 lg:overflow-visible lg:px-0 min-h-screen"
      )}>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <Home/>
                </motion.div>
              }
            />
            {/*<Route*/}
            {/*  path="/services"*/}
            {/*  element={*/}
            {/*    <motion.div*/}
            {/*      initial={{ opacity: 0 }}*/}
            {/*      animate={{ opacity: 1 }}*/}
            {/*      exit={{ opacity: 0 }}*/}
            {/*      transition={{ duration: 0.5 }}*/}
            {/*    >*/}
            {/*      <Services />*/}
            {/*    </motion.div>*/}
            {/*  }*/}
            {/*/>*/}
            <Route
              path="/MatrixGen"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <MatrixGen/>
                </motion.div>
              }
            />
            <Route
              path="/pricing"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <Pricing/>
                </motion.div>
              }
            />
            <Route
              path="/about-us"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <About/>
                </motion.div>
              }
            />
            <Route
              path="/login"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <Login/>
                </motion.div>
              }
            />
            <Route
              path="/sign-up"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <Signup/>
                </motion.div>
              }
            />
            <Route
              path="/reset-password"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <PasswordResetRequest/>
                </motion.div>
              }
            />
            <Route
              path="/reset-password/:token"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <PasswordReset/>
                </motion.div>
              }
            />
            <Route
              path="*"
              element={
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  exit={{opacity: 0}}
                  transition={{duration: 0.5}}
                >
                  <ErrorPage/>
                </motion.div>
              }
            />
          </Routes>
        </AnimatePresence>
      </div>

      {!hideNavBarAndFooter && <Footer/>}
    </div>
  );
};

export default GeneralRoutes;
