// src/pages/general/solutions/RVTM/components/Classification.js

import { ClipboardDocumentListIcon, ShieldCheckIcon, PencilSquareIcon, ExclamationCircleIcon, ClockIcon, CurrencyDollarIcon, ChartBarIcon, ChatBubbleBottomCenterTextIcon, BoltIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import { containerVariants, textVariants } from './variants'; // Adjust the path
import AnimatedListItem from './AnimatedListItem'; // Adjust the path

export default function Classification() {
  return (
    <div className="relative lg:pr-4 mb-16 lg:max-w-2xl">
      <p className="mt-2 text-3xl font-bold tracking-tight text-custom-white sm:text-5xl">
        <span className="text-gradient-primary">Step 2: </span> Matrix Classification
      </p>
      <h1 className="mt-2 text-lg font-bold tracking-tight text-accent sm:text-xl">
        Organizing Requirements with Precision
      </h1>

      {/* Introduction Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-25% 0px -25% 0px' }} // Adjusted margin here
        variants={textVariants}
      >
        <p className="mt-8 sm:mt-16 text-xl leading-8 text-primary-one">What is a Requirements Matrix?</p>
        <div className="text-base leading-7 text-secondary">
          <p>
            A Requirement Verification Traceability Matrix (RVTM) is a fundamental tool in systems engineering that maps and traces user requirements throughout the project lifecycle. It ensures that all requirements are accounted for, implemented correctly, and verified at each stage of the project.
          </p>
        </div>
      </motion.div>

      {/* Features List */}
      <motion.ul
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-25% 0px -25% 0px' }} // Adjusted margin here
        variants={containerVariants}
        role="list"
        className="mt-8 space-y-8 text-gray-600"
      >
        <AnimatedListItem
          icon={ClipboardDocumentListIcon}
          title="Map and Trace Requirements"
          description="Track the status and verification of each requirement."
          margin={-25}
        />
        <AnimatedListItem
          icon={ShieldCheckIcon}
          title="Ensure Compliance"
          description="Verify that all project requirements are in accordance with relevant standards and specifications."
          margin={-25}
        />
        <AnimatedListItem
          icon={PencilSquareIcon}
          title="Support Project Development"
          description="Provide a structured format for organizing and managing requirements."
          margin={-25}
        />
        <AnimatedListItem
          icon={ExclamationCircleIcon}
          title="Minimize Errors"
          description="Identify and address gaps or inconsistencies in requirements early in the project lifecycle."
          margin={-25}
        />
      </motion.ul>

      {/* Drawbacks Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-25% 0px -25% 0px' }} // Adjusted margin here
        variants={textVariants}
      >
        <p className="mt-8 sm:mt-16 text-xl leading-8 text-primary-one">Drawbacks of Manual Classification</p>
        <div className="text-base leading-7 text-secondary">
          <p>
            Manual classification of requirements in an RVTM presents several challenges. The process can be
            <span className="text-accent font-semibold"> time-consuming</span>, often extending over 1-2 years and significantly delaying project timelines. It is also
            <span className="text-accent font-semibold"> costly</span>, with high labor costs associated with employing a team of engineers to manually classify and verify requirements. Moreover, manual efforts often lead to
            <span className="text-accent font-semibold"> inconsistent</span> interpretations of requirements, resulting in errors and reworks. Additionally, human errors can lead to
            <span className="text-accent font-semibold"> incorrect classifications</span>, impacting the overall quality of the RVTM.
          </p>
        </div>
      </motion.div>

      {/* Solution Section */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-25% 0px -25% 0px' }} // Adjusted margin here
        variants={textVariants}
      >
        <h2 className="mt-8 sm:mt-16 text-2xl font-bold tracking-tight text-custom-white">No resources? No problem.</h2>
        <p className="text-base leading-7 text-secondary">
          To address the challenges of manual requirement classification, our solution leverages advanced AI technologies. This approach automates the extraction and classification of requirements, ensuring consistency and speed while tailoring the process to meet the specific needs of each project and client.
        </p>
      </motion.div>

      {/* Advantages List */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-25% 0px -25% 0px' }} // Adjusted margin here
        variants={textVariants}
      >
        <p className="mt-8 sm:mt-16 text-xl leading-8 text-primary-one">Advantages of Using Our Solution:</p>
      </motion.div>

      <motion.ul
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-25% 0px -25% 0px' }} // Adjusted margin here
        variants={containerVariants}
        role="list"
        className="mt-4 space-y-8 text-gray-600"
      >
        <AnimatedListItem
          icon={ClockIcon}
          title="Time Efficiency"
          description="Drastically reduces the time needed to develop an RVTM, enabling quicker project progression."
          margin={-25}
        />
        <AnimatedListItem
          icon={CurrencyDollarIcon}
          title="Cost Reduction"
          description="Lowers labor costs by eliminating the need for extensive manual classification."
          margin={-25}
        />
        <AnimatedListItem
          icon={ChartBarIcon}
          title="Improved Accuracy"
          description="Ensures consistent and accurate classification, reducing the risk of errors and the need for rework."
          margin={-25}
        />
        <AnimatedListItem
          icon={ChatBubbleBottomCenterTextIcon}
          title="Enhanced Project Clarity"
          description="Provides well-organized requirements, improving communication among stakeholders."
          margin={-25}
        />
        <AnimatedListItem
          icon={BoltIcon}
          title="Immediate Implementation"
          description="Streamlines the transition from planning to execution."
          margin={-25}
        />
      </motion.ul>
    </div>
  );
}
