import React, { lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentMainContent, setCurrentTab} from '../../../utils/redux/uiSlice';

import ErrorPage from '../../../components/ErrorPage'
import SideBar from "../../../layouts/application/SideBar";
import MainHeader from "./components/MainHeader";

// Lazy load components for better performance
const contentConfig = {
  MatrixGen: {
    tabs: [
      { name: 'Multi-File', component: lazy(() => import('./MatrixGen/Multi-File/FileQueryForm')) },
      { name: 'Help', component: lazy(() => import('./MatrixGen/Help/Help')) },
      // { name: 'Single', component: lazy(() => import('./MatrixGen/single/SingleQueryForm')) },
    ],
    asides: [
      { name: 'Multi-File', component: lazy(() => import('./MatrixGen/aside/MultiFileQueriesHistory')) },
      // { name: 'Single', component: lazy(() => import('./MatrixGen/aside/SingleQueriesHistory')) },
    ]
  },
  // Settings: {
  //   tabs: [
  //     { name: 'Help', component: lazy(() => import('./settings/Profile')) },
  //   ],
  //   asides: [
  //     { name: 'Single', component: lazy(() => import('./MatrixGen/aside/SingleQueriesHistory')) },
  //     { name: 'Multiple', component: lazy(() => import('./MatrixGen/aside/MultiFileQueriesHistory')) },
  //   ]
  // }
  // Add more main contents and their tabs and asides here
};

export default function Example() {
  const dispatch = useDispatch();
  const { currentSolution, currentTab, currentAside } = useSelector((state) => state.ui);

  const mainContentConfig = contentConfig[currentSolution];
  const currentTabConfig = mainContentConfig?.tabs?.find(tab => tab.name === currentTab);
  const currentAsideConfig = mainContentConfig?.asides?.find(aside => aside.name === currentAside);

  const MainContentComponent = currentTabConfig?.component;
  const AsideContentComponent = currentAsideConfig?.component;

  return (
    <div>
      <SideBar />

      <main className="lg:pl-72 lg:pr-96 bg-gray-800 min-h-screen ring-white/5 pb-8">
        <MainHeader
          tabs={mainContentConfig?.tabs || []}
        />
        <Suspense fallback={<div />}>
          {MainContentComponent ? (
            <MainContentComponent />
          ) : (
            <div className="flex items-center justify-center min-h-screen overflow-y-auto">
              <p className="text-center text-gradient-primary font-bold text-3xl">
                This Section is Under Construction
              </p>
            </div>
          )}
        </Suspense>
      </main>

      <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto ring-1 ring-white/5 bg-gray-900 px-4 py-6 sm:px-6 lg:px-8 xl:block">
        <Suspense fallback={<div />}>
          {AsideContentComponent ? (
            <AsideContentComponent />
          ) : (
            <div className="flex items-center justify-center min-h-screen">
              <p className="text-center text-gradient-primary font-bold text-3xl">
                This Section is Under Construction
              </p>
            </div>
          )}
        </Suspense>
      </aside>
    </div>
  );
}
