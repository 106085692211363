// fileSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files: [],
};

const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    addFile: (state, action) => {
      state.files.push(action.payload);
    },
    removeFile: (state, action) => {
      state.files = state.files.filter(file => file.id !== action.payload);
    },
    resetFiles: (state, action) => {
      state.files = []
    }
  },
});

export const { addFile, removeFile, resetFiles } = fileSlice.actions;
export default fileSlice.reducer;
