// TextField.js
import React, { useEffect, useState } from 'react';
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

const TextField = ({ id, label, type, placeholder, register, errors, validationRules = {}, requirements, getValues }) => {
  const [isValid, setIsValid] = useState(false);
  const hasError = !!errors[id];

  useEffect(() => {
    const validateField = () => {
      const value = getValues(id);
      if (validationRules.required && !value) {
        setIsValid(false);
        return;
      }
      if (validationRules.pattern && !validationRules.pattern.value.test(value)) {
        setIsValid(false);
        return;
      }
      if (validationRules.minLength && value.length < validationRules.minLength.value) {
        setIsValid(false);
        return;
      }
      setIsValid(true);
    };

    validateField();
  }, [getValues, id, validationRules]);

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-custom-white">
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          {...register(id, validationRules)}
          id={id}
          name={id}
          type={type}
          placeholder={placeholder}
          aria-invalid={hasError}
          aria-describedby={`${id}-error`}
          className={`block w-full rounded-md border-0 bg-white/5 py-1.5 ${
            hasError || isValid ? 'pr-10' : 'pr-3'
          } ring-1 ring-inset ${
            hasError ? 'ring-red-300' : isValid ? 'ring-green-300' : 'ring-white/10'
          } text-custom-white placeholder:text-gray-500 focus:ring-2 focus:ring-inset ${
            hasError ? 'focus:ring-red-500' : isValid ? 'focus:ring-green-500' : 'focus:ring-accent-hover'
          } sm:text-sm sm:leading-6`}
        />
        {hasError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon aria-hidden="true" className="h-5 w-5 text-red-500" />
          </div>
        )}
        {isValid && !hasError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-500" />
          </div>
        )}
      </div>
      {hasError && (
        <p id={`${id}-error`} className="mt-2 text-sm text-red-600">
          {errors[id]?.message}
        </p>
      )}
      {requirements && (
        <ul id={`${id}-requirements`} className="mt-2 text-sm text-gray-500 list-disc pl-5">
          {requirements.map((req, index) => (
            <li key={index}>{req}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextField;
