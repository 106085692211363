// Teams component
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTeam } from '../../../../utils/redux/uiSlice';
import AxiosInstance from "../../../../components/AxiosInstance";

import AsideButton from "./AsideButton";

export default function Teams({ setSidebarOpen }) {
  const dispatch = useDispatch();
  const { currentTeam } = useSelector((state) => state.ui);

  const [teamsList, setTeamsList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get('teams/');
        const fetchedTeams = response.data;

        const teamsArray = fetchedTeams.map((team) => ({
          id: team.id,
          name: team.name,
          initial: team.name.charAt(0).toUpperCase(),
          current: team.name === 'General',
        }));

        const sortedTeams = teamsArray.sort((a, b) => {
          if (a.name === 'General') return -1;
          if (b.name === 'General') return 1;
          return a.name.localeCompare(b.name);
        });

        setTeamsList(sortedTeams);
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };
    fetchData();
  }, []);

  const handleTeamsChange = (item) => {
    dispatch(setCurrentTeam({...item, current: true}));
    setTeamsList(teamsList.map(team =>
      team.name === item.name
        ? { ...team, current: true }
        : { ...team, current: false }
    ));
    setSidebarOpen(false);
  };

  return (
    <ul role="list" className="-mx-2 mt-2 space-y-1">
      {teamsList.map((item) => (
        <li key={item.name}>
          <AsideButton item={item} onClick={() => handleTeamsChange(item)} />
        </li>
      ))}
    </ul>
  );
}
