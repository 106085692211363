import React from 'react';
import Accordion from './Accordion';
import { TopSectionPolygon, BottomRightSectionPolygon } from '../../../../components/BackgroundPolygons';
import { GridThree } from '../../../../components/Grid';

import Norbert from '../../../../assets/portrait_square.jpg';

export default function Members() {
  return (
    <>
      <div className="mx-auto max-w-7xl py-24 sm:py-32 text-left">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-3 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-3 xl:grid-rows-1 xl:gap-x-8">
          <div className="lg:col-span-2 xl:col-span-2">
            <h1 className="text-4xl font-bold tracking-tight text-[#FFFFFF] sm:text-5xl mt-12">
              Norbert Irsik. <span className="text-gradient-primary">AI Engineer.</span>
            </h1>
            <p className="mt-6 text-2xl leading-8 text-[#E5E5E5]">
              Engineering Graduate with a Bachelor of Applied Science from the University of Toronto, proficient in
              <span className="font-bold text-accent"> Python, PyTorch Library, Django Framework, React Framework, HTML, CSS, and JavaScript</span>
              , with industry experience in systems engineering.
            </p>
          </div>
          <div className="lg:col-span-1 xl:col-span-1">
            <img
              alt=""
              src={Norbert}
              className="mt-7 aspect-[3/4] w-full max-w-lg rounded-2xl object-cover sm:mt-11 lg:mt-0 xl:mt-25"
            />
          </div>
        </div>
      </div>

    </>
  );
}
