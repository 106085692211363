import React from 'react';

import Hero from "./components/Hero";
import Benefits from "./components/Benefits";
import Features from "./components/Features";
import CTA from "./components/CTA";

export default function Home() {
  return (
    <>
        <Hero />
        <Benefits />
        <Features />
        <CTA />
    </>
  );
};
