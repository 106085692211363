// src/pages/general/solutions/MatrixGen/components/Formatting.js

import { DocumentTextIcon, BookmarkSlashIcon, ViewColumnsIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import { containerVariants, textVariants } from './variants'; // Adjust the path
import AnimatedListItem from './AnimatedListItem'; // Adjust the path

export default function Formatting() {
  return (
    <div className="relative lg:pr-4 mb-32 lg:max-w-2xl">
      <p className="mt-2 text-3xl font-bold tracking-tight text-custom-white sm:text-5xl">
        <span className="text-gradient-primary">Step 1:</span> PDF Formatter
      </p>
      <h1 className="mt-2 text-lg font-bold tracking-tight text-accent sm:text-xl">
        Effortlessly Transform Unstructured PDFs into Organized Data.
      </h1>

      {/* Solution Overview */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0 }}
        variants={textVariants}
      >
        <p className="mt-8 sm:mt-16 text-xl leading-8 text-primary-one">Step Overview</p>
        <div className="text-base leading-7 text-secondary">
          <p>
            In this step, our AI model processes your uploaded PDF, accurately identifying the main text and removing
            headers and footers. It then transforms the cleaned content into a
            <span className="text-accent font-bold"> structured table</span>, ready for further analysis.
            This ensures the document is organized and primed for the next steps in the pipeline.
          </p>
        </div>
      </motion.div>

      {/* Features List */}
      <motion.ul
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0 }}
        variants={containerVariants}
        role="list"
        className="mt-8 space-y-8 text-gray-600"
      >
        <AnimatedListItem
          icon={DocumentTextIcon}
          title="Accurate Text Identification"
          description="The AI precisely detects and extracts relevant content from the PDF, ensuring no important details are missed."
          margin={0}
        />
        <AnimatedListItem
          icon={BookmarkSlashIcon}
          title="Header and Footer Removal"
          description="Unnecessary headers and footers are automatically removed to declutter the document."
          margin={0}
        />
        <AnimatedListItem
          icon={ViewColumnsIcon}
          title="Automated Tabular Transformation"
          description="The cleaned text is converted into a structured table, making it easier to analyze and process."
          margin={0}
        />
      </motion.ul>
    </div>
  );
}
