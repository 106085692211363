// src/pages/general/solutions/MatrixGen/MatrixGen.js

import { useState, useRef, useEffect}  from "react";
import { GridOne } from '../../../../components/Grid'

import Formatting from './components/Formatting'
import Classification from './components/Classification'

import PDFImage from '../../../../assets/PDF.png'
import RVTMImage from '../../../../assets/RVTMimage.png'

import {AnimatePresence, motion} from 'framer-motion'

export default function MatrixGen() {
  const [currentImage, setCurrentImage] = useState(PDFImage);

  const formattingRef = useRef(null)
  const classificationRef = useRef(null)

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.4,
    };

    const handleFormattingIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentImage(PDFImage);
        }
      });
    };

    const handleClassificationIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentImage(RVTMImage);
        }
      });
    };

    const formattingObserver = new IntersectionObserver(handleFormattingIntersect, observerOptions);
    const classificationObserver = new IntersectionObserver(handleClassificationIntersect, observerOptions);

    if (formattingRef.current) {
      formattingObserver.observe(formattingRef.current);
    }

    if (classificationRef.current) {
      classificationObserver.observe(classificationRef.current);
    }

    return () => {
      formattingObserver.disconnect();
      classificationObserver.disconnect();
    };
  }, []);

  return (
    <div className="relative isolate overflow-hidden lg:overflow-visible lg:px-0">
      <GridOne />
      {/* Header Section */}
      <div className="mx-auto max-w-2xl text-center mb-24 sm:mb-32">
        <h1 className="text-4xl font-bold tracking-tight text-custom-white sm:text-6xl">
          Matrix<span className="text-gradient-primary">Gen™</span>
        </h1>
        <p className="mt-4 text-lg leading-8 text-secondary">
          We take a simple PDF project requirement document, format it into individual requirements, classify it,
          and provide a tabular formatted document that can be imported into DOORS in a
          <span className="text-accent font-bold"> single click.</span>
        </p>
      </div>
      <div
        className="mx-auto grid max-w-full grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-[2fr_1fr] lg:items-start lg:gap-y-10">
        <div
          className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-7xl lg:gap-x-8 lg:px-8">
          {/* Include your solution components here */}
          <div ref={formattingRef}>
            <Formatting/>
          </div>
          <div ref={classificationRef}>
            <Classification/>
          </div>
          {/* Add more solution components as needed */}
        </div>
        <div
          className="-ml-12 -mt-12 p-12 pl-0 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden hidden lg:block"
        >
          <AnimatePresence mode="wait">
            <motion.img
              key={currentImage}
              alt="Solution Image"
              src={currentImage}
              className="w-[60em] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[35rem]"
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 0.5}}
            />
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
