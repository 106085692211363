import Timeline from './Timeline'

export default function Hero() {
	return (
		<>
			<div className="mx-auto max-w-2xl text-center mb-24 sm:mb-32">
				<h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
					Innovation in our <span className="text-gradient-primary">DNA</span>
				</h2>
				<p className="mt-6 text-lg leading-8 text-gray-300">
					Our motto is simple. Always striving to be better, with the goal of surpassing who we were yesterday.
				</p>
			</div>

			<Timeline/>
		</>
	)
}