import axios from 'axios'

// Robi: testez ca nu merge coneciunea front end
//const baseURL = 'http://localhost:8000/'
//const LOCAL_URL = 'http://localhost:8000/'
//const REACT_APP_BASE_URL = 'https://www.irsiks.com/'
const apiUrl = process.env.REACT_APP_API_BASE_URL


const AxiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 3600000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

AxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('Token');
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check if the error is due to an expired or invalid token
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('Token');
      window.location.href = '/login';
    }
    // Return a rejected promise to propagate the error
    return Promise.reject(error);
  }
);

export default AxiosInstance;