// useInViewAnimation.js
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';

export const useInViewAnimation = (ref) => {
  const controls = useAnimation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start('visible');
            observer.unobserve(entry.target); // Stop observing after animation starts
          }
        });
      },
      {
        threshold: 0.5, // Trigger when 50% of the component is visible
        rootMargin: '0px 0px -25% 0px', // Adjust viewport margin
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [controls, ref]);

  return controls;
};
