// singleQuerySlice.js
import { createSlice } from '@reduxjs/toolkit';

export const singleQuerySlice = createSlice({
  name: 'singleQuery',
  initialState: {
    results: null,
    input: null
  },
  reducers: {
    setInput: (state, action) => {
      state.input = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
    clearResults: (state) => {
      state.results = null;
    },
  },
});

export const { setResults, clearResults, setInput } = singleQuerySlice.actions;

export default singleQuerySlice.reducer;
