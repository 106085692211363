import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import { setCurrentSolution, setCurrentTab } from '../../../utils/redux/uiSlice'
import { RightLogoutButton } from '../../../components/LogoutButton'

function User() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user_data);

	return (
		<>
			<a
				href="#"
				// onClick={(e) => {
				// 	e.preventDefault();
				// 	dispatch(setCurrentSolution('Settings'));
				// 	dispatch(setCurrentTab('Help'));
      	// }}
				className="flex items-center gap-x-4 lg:px-6 lg:py-3 sm:px-2 sm:py-1 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
			>
				<span
					className={`bg-primary-two h-8 w-8 flex items-center justify-center rounded-lg border text-lg font-semibold border-black text-unslected-text text-black`}
				>
					{user && user.initials ? user.initials : ''}
				</span>

				<span className="sr-only">Your profile</span>
				<span className="hidden sm:inline text-custom-white" aria-hidden="true">
          {user ? `${user.first_name} ${user.last_name}` : 'Loading...'}
        </span>
			</a>

			<RightLogoutButton extra_class="lg:hidden"/>

		</>
	)
}

export default User;