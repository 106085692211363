import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModel } from '../../../../utils/redux/uiSlice';
import AxiosInstance from "../../../../components/AxiosInstance";

import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

export default function ModelMenu() {
  const dispatch = useDispatch();
  const { currentSolution, currentModel, currentTeam } = useSelector((state) => state.ui);

  const [modelsList, setModelsList] = useState([]);

  useEffect(() => {
    console.log('currentSolution:', currentSolution);
    console.log('currentTeam:', currentTeam);

    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get(`/${currentSolution.toLowerCase()}/models/`, {
          params: { team_name: currentTeam.name }
        });
        console.log('Response data:', response.data);

        const fetchedModels = response.data;

        // Sort models by created_at date
        const sortedModels = fetchedModels.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setModelsList(sortedModels);
        dispatch(setCurrentModel(sortedModels[0]));
        console.log('Sorted models:', sortedModels);
      } catch (error) {
        console.error('Error fetching models data:', error);
      }
    };

    if (currentSolution && currentTeam) {
      fetchData();
    }
  }, [currentSolution, currentTeam]);

  const handleModelChange = (model) => {
    dispatch(setCurrentModel(model));
  };

  console.log('Current Model: ', currentModel)

    return (
    <div className="w-full max-w-xs">
      <Listbox value={currentModel} onChange={handleModelChange} disabled={!currentModel}>
        <div className="flex items-center justify-end space-x-4">
          <Listbox.Label className="flex-shrink-0 text-sm font-medium leading-6 text-custom-white">Select Model:</Listbox.Label>
          <div className="relative w-48">
            <Listbox.Button className={`relative w-full rounded-md py-1.5 pl-3 pr-10 text-left font-semibold shadow-sm ring-1 ring-inset focus:outline-none sm:text-sm sm:leading-6 ${currentModel ? 'cursor-default bg-gray-950 text-primary-one ring-accent-hover focus:ring-2 focus:ring-accent-hover text-glow-model-menu' : 'cursor-not-allowed bg-gray-700 text-red-500'}`}>
              <span className="block truncate ml-1 text-center">{currentModel ? currentModel.name : 'No Models Available'}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
              </span>
            </Listbox.Button>

            {currentModel && (
              <Listbox.Options
                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {modelsList.map((model) => (
                  <Listbox.Option
                    key={model.id}
                    value={model}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-8 pr-4 ${active ? 'bg-gray-800 text-white' : 'text-custom-white'}`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                          {model.name} ({new Date(model.created_at).toLocaleDateString()})
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-accent">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            )}
          </div>
        </div>
      </Listbox>
    </div>
  );
}
