// Features.js
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { useInViewAnimation } from '../../../../hooks/useInViewAnimation';
import { featureVariants, imageVariants } from './variants';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';
import AppScreenshot from '../../../../assets/AppScreenshot.png';

const features = [
  {
    name: 'User-Friendly Interface.',
    description:
      'Enjoy an intuitive and easy-to-navigate interface designed to simplify the process of managing and deploying your models.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Seamless Integration.',
    description: 'Integrate your models seamlessly with existing systems and workflows, allowing for smooth and efficient operations.',
    icon: LockClosedIcon,
  },
  {
    name: 'Comprehensive Support.',
    description: 'Access our dedicated support team and extensive resources to help you at every stage, from consultation to deployment.',
    icon: ServerIcon,
  },
];

export default function Features() {
  const ref = useRef();
  const imageRef = useRef();
  const controls = useInViewAnimation(ref);
  const imageControls = useInViewAnimation(imageRef);

  return (
    <div className="relative isolate">
      <div className="overflow-hidden py-24 sm:py-32">
        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
            {/* Text Section */}
            <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
              {/* ... */}
              <dl ref={ref} className="mt-10 max-w-xl space-y-8 text-base leading-7 text-secondary lg:max-w-none">
                {features.map((feature, index) => (
                  <motion.div
                    key={feature.name}
                    variants={featureVariants}
                    initial="hidden"
                    animate={controls}
                    custom={index}
                    className="relative pl-9 lg:text-left"
                  >
                    <dt className="inline font-semibold text-custom-white">
                      <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-accent" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </motion.div>
                ))}
              </dl>
            </div>
            {/* Image Section */}
            <div className="sm:px-6 lg:px-0">
              <motion.div
                ref={imageRef}
                variants={imageVariants}
                initial="hidden"
                animate={imageControls}
                className="relative isolate overflow-hidden bg-gradient-primary px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none"
              >
                {/* ... */}
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    alt="Product screenshot"
                    src={AppScreenshot}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                  />
                </div>
                {/* ... */}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
