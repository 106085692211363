import React from 'react';

function TopSectionPolygon() {
    return (
        <>
            <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(60% 44.1%, 90% 61.6%, 87.5% 26.9%, 75.5% 0.1%, 70.7% 2%, 62.5% 32.5%, 50.2% 62.4%, 42.4% 68.1%, 37.5% 58.3%, 35.2% 34.5%, 17.5% 76.7%, 0.1% 64.9%, 7.9% 100%, 17.6% 76.8%, 66.1% 97.7%, 60% 44.1%)',
                    }}
                    className="relative left-[calc(50%-20rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-accent opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                />
            </div>
        </>
    );
}

function BottomRightSectionPolygon() {
    return (
        <>
            <div
                aria-hidden="true"
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-accent opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                />
            </div>
        </>

    );
}

function BottomLeftSectionPolygon() {
    return (
        <>
            <div
                aria-hidden="true"
                className="absolute inset-x-0 top-[calc(100%-8rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-20rem)]"
            >
                <div
                    style={{
                        clipPath:
                            'polygon(25.9% 55.9%, 0% 38.4%, 2.5% 73.1%, 14.5% 99.9%, 19.3% 98%, 27.5% 67.5%, 39.8% 37.6%, 47.6% 31.9%, 52.5% 41.7%, 54.8% 65.5%, 72.5% 23.3%, 99.9% 35.1%, 82.1% 0%, 72.4% 23.2%, 23.9% 2.3%, 25.9% 55.9%)',
                    }}
                    className="relative left-[calc(50%-3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-accent opacity-20 sm:left-[calc(50%-36rem)] sm:w-[72.1875rem]"
                />
            </div>
        </>
    );
}

function CenterLeftPolygon() {
  return (
    <>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              'polygon(20% 0%, 40% 20%, 60% 0%, 80% 20%, 100% 0%, 80% 40%, 100% 60%, 80% 80%, 100% 100%, 60% 80%, 40% 100%, 20% 80%, 0% 100%, 20% 60%, 0% 40%, 20% 20%)',
          }}
          className="relative left-[calc(25%-6rem)] aspect-[1155/678] w-[24rem] -translate-x-1/2 bg-accent opacity-20 sm:left-[calc(25%-18rem)] sm:w-[48rem]"
        />
      </div>
    </>
  );
}

function CenterPolygon() {
  return (
    <>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              'polygon(50% 0%, 60% 20%, 80% 0%, 100% 20%, 80% 40%, 100% 60%, 80% 80%, 100% 100%, 60% 80%, 40% 100%, 20% 80%, 0% 100%, 20% 60%, 0% 40%, 20% 20%)',
          }}
          className="relative left-[calc(50%-6rem)] aspect-[1155/678] w-[24rem] -translate-x-1/2 bg-accent opacity-20 sm:left-[calc(50%-18rem)] sm:w-[48rem]"
        />
      </div>
    </>
  );
}

function CenterRightPolygon() {
  return (
    <>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              'polygon(30% 0%, 50% 20%, 70% 0%, 90% 20%, 100% 0%, 90% 40%, 100% 60%, 90% 80%, 100% 100%, 70% 80%, 50% 100%, 30% 80%, 10% 100%, 30% 60%, 10% 40%, 30% 20%)',
          }}
          className="relative left-[calc(75%-6rem)] aspect-[1155/678] w-[24rem] -translate-x-1/2 bg-accent opacity-20 sm:left-[calc(75%-18rem)] sm:w-[48rem]"
        />
      </div>
    </>
  );
}


export { TopSectionPolygon, BottomRightSectionPolygon, BottomLeftSectionPolygon, CenterLeftPolygon, CenterPolygon, CenterRightPolygon};