import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { TopSectionPolygon, BottomRightSectionPolygon } from '../../../../components/BackgroundPolygons';

const timeline = [
  {
    name: 'Founded company',
    description:
      'Established the company with a vision to revolutionize AI in the construction industry. Began operations with a small team of dedicated professionals.',
    date: 'Nov 2022',
    dateTime: '2022-11',
  },
  {
    name: 'Developed First AI Solution',
    description:
      'Successfully created the first AI solution tailored for systems engineering, with the ability to enhance efficiency and accuracy significantly.',
    date: 'Dec 2023',
    dateTime: '2023-12',
  },
  {
    name: 'Developed AI Platform',
    description:
      'Launched a comprehensive AI platform offering advanced tools and integrations. Streamlined workflows for clients and expanded service offerings.',
    date: 'July 2024',
    dateTime: '2024-07',
  },
  {
    name: 'Global launch of product',
    description:
      'Introducing the AI platform to a global audience. The goal is to secure Multi-File international partnerships, driving new growth potential in the industry.',
    date: 'Nov 2024',
    dateTime: '2024-11',
  },
];

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.3,
      duration: 1,
      ease: 'easeOut',
    },
  }),
};

const itemVariantsSm = {
  hidden: { opacity: 0, y: 50 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.3,
      duration: 1,
      ease: 'easeOut',
    },
  }),
};

export default function Timeline() {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5});

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const isSmallScreen = window.innerWidth <= 640;

  return (
    <div ref={ref} className="mb-24 sm:mb-32">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {timeline.map((item, index) => (
            <motion.div
              key={item.name}
              custom={index}
              initial="hidden"
              animate={controls}
              variants={isSmallScreen ? itemVariantsSm : itemVariants}
              className="relative"
            >
              <time
                dateTime={item.dateTime}
                className="flex items-center text-sm font-semibold leading-6 text-accent"
              >
                <svg viewBox="0 0 4 4" aria-hidden="true" className="mr-4 h-1 w-1 flex-none">
                  <circle r={2} cx={2} cy={2} fill="currentColor" />
                </svg>
                {item.date}
                <div
                  aria-hidden="true"
                  className="absolute -ml-2 h-px w-screen -translate-x-full bg-accent/30 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                />
              </time>
              <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gradient-primary text-left">
                {item.name}
              </p>
              <p className="mt-1 text-base leading-7 text-secondary text-left">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
      {/*<BottomRightSectionPolygon />*/}
    </div>
  );
}
