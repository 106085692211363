import { configureStore } from '@reduxjs/toolkit';

import uiReducer from './uiSlice';
import fileReducer from './filesSlice'
import userReducer from './userSlice'

import fileQueryStepsReducer from '../../pages/application/dashboard/MatrixGen/Multi-File/fileQuerySilce'
import singleQueryReducer from '../../pages/application/dashboard/MatrixGen/single/singleQuerySlice'

const store = configureStore({
  reducer: {
    ui: uiReducer,
    files: fileReducer,
    user: userReducer,

    fileQuerySteps: fileQueryStepsReducer,
    singleQuery: singleQueryReducer

  },
});

export default store;