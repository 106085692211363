import Button from "./Button";

export default function ErrorPage() {
  return (
    <div className="text-center">
      <p className="text-3xl font-semibold text-gradient-primary">404</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-custom-white sm:text-5xl">Page not found</h1>
      <p className="mt-6 text-base leading-7 text-secondary">Sorry, we couldn’t find the page you’re looking for.</p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        {/*<a*/}
        {/*  href="#"*/}
        {/*  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
        {/*>*/}
        {/*  Go back home*/}
        {/*</a>*/}
        <Button href="/">
            Go back home
        </Button>
        <a href="mailto:irsik.solutions@gmail.com" className="text-sm font-semibold text-custom-white">
          Contact support <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  )
}
