import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, onClick, type = 'button', className = '', href }) => {
  const commonClasses = "rounded-md bg-gradient-primary px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gradient-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400";

  if (href) {
    return (
      <a href={href} className={`${commonClasses} ${className}`}>
        {children}
      </a>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${commonClasses} ${className}`}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  href: PropTypes.string,
};

export default Button;
