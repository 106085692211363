// About.js
import Members from './sections/Members';
import Hero from './sections/Hero'
import { GridAbout } from '../../../components/Grid';


export default function Example() {
  return (
      <div>
        <GridAbout />
        <Hero />
        <Members/>
      </div>
  )
}
