import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ilogo from "../../assets/i-logo.png";
import AxiosInstance from '../../components/AxiosInstance';
import { TopSectionPolygon, BottomRightSectionPolygon } from '../../components/BackgroundPolygons';
import { SuccessAlert, ErrorAlert } from '../../components/Alerts';

export default function Login() {
  const navigate = useNavigate();

  const [showRegistrationMessage, setShowRegistrationMessage] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('registrationSuccess') === 'true') {
      setShowRegistrationMessage(true);
      localStorage.removeItem('registrationSuccess');  // Clear the flag
    }
  }, []);

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm();

const onSubmit = async (data) => {
  try {
    //Robi: changed posting to login_backend (was conflicting to frontend login page set in GeneralRoutes)
    const response = await AxiosInstance.post('login_backend/', {
      email: data.email,
      password: data.password,
    });

    localStorage.setItem('Token', response.data.token);
    navigate('/dashboard');

  } catch (error) {
    console.log('Error response:', error.response);

    if (error.response && error.response.status === 400) {
      // Handle invalid credentials
      const errorData = error.response.data;
      if (errorData && errorData.non_field_errors) {
        setError('root', {
          type: 'manual',
          message: errorData.non_field_errors[0], // Access the first non-field error message
        });
      }
    } else {
      setError('root', {
        type: 'manual',
        message: 'An unexpected error occurred. Please try again later!',
      });
      console.error('An unexpected error occurred:', error);
    }
  }
};

  return (
    <>
      <div className="relative isolate overflow-clip">
        <TopSectionPolygon />
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <a href="/">
              <img
                alt="Your Company"
                src={ilogo}
                className="mx-auto h-10 w-auto"
              />
            </a>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              {showRegistrationMessage && (
                  <SuccessAlert message="Your account has been successfully created."/>
              )}
              {errors.root && (
                  <ErrorAlert message={errors.root.message}/>
              )}
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white text-left">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                      id="email"
                      type="email"
                      autoComplete="email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Email address is invalid'
                        }
                      })}
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-accent-hover sm:text-sm sm:leading-6"
                  />
                  {errors.email && (
                      <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
                  )}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">
                    Password
                  </label>
                  <div className="text-sm">
                    <a href="/reset-password" className="font-semibold text-accent hover:text-accent-hover">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                      id="password"
                      type="password"
                      autoComplete="current-password"
                      {...register('password', {required: 'Password is required'})}
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-accent-hover sm:text-sm sm:leading-6"
                  />
                  {errors.password && (
                      <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>
                  )}
                </div>
              </div>

              <div>
                <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gradient-primary px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-gradient-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                >
                  Sign in
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-400">
              Have a secret login key?{' '}
              <a href="/sign-up" className="font-semibold leading-6 text-accent hover:text-accent-hover">
                Sign up!
              </a>
            </p>

          </div>
        </div>
        <BottomRightSectionPolygon/>
      </div>
    </>
  );
}
