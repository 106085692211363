import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

const portfolioItems = [
  {
    title: 'Project #1 - Django-React Website',
    content: (
      <>
        <strong className="text-accent">This website has been built on the Django framework.</strong> The project involved developing an AI-interface web application designed to enhance construction efficiency by enabling clients to utilize their custom developed models built by our team through consultation. WebSockets were used to enable the AI models to receive constant training feedback, ensuring real-time updates on the webpage for the user. The front-end is built with JavaScript, CSS, and HTML for a responsive user interface while the back-end utilizes Python and SQL for robust data management and processing.
      </>
    ),
  },
  {
    title: 'Project #2 - AI Weather Prediction Model',
    content: (
      <>
        <strong className="text-accent">TurboCast: Experimental short-term weather CNN-GRU prediction model</strong> is an advanced, short-term local weather prediction model developed during my time at the University of Toronto. It leveraged a hybrid CNN-GRU architecture for high-performance forecasting combining inputs from both weather radars and weather stations to forecast weather conditions for the subsequent two hours following the input period.
      </>
    ),
  },
  {
    title: 'Project #3 - Automated Matrix Tracker',
    content: (
      <>
        During my time at Kiewit, I developed an automated <strong className="text-accent">Requirement Verification Traceability Matrix (RVTM)</strong> using Python for the Union Station Enhancement Project. The script extracted daily compliance data from an Excel matrix, enabling real-time tracking of requirement fulfillment across different disciplines. The solution was integrated into the project's KPI dashboard, significantly enhancing the Systems Engineering Team's capability to monitor, analyze, and report on project compliance and performance metrics. The tool facilitated data-driven decision-making and improved project transparency and accountability.
      </>
    ),
  },
];

export default function Accordion() {
	return (
    <section aria-labelledby="details-heading">
      <h2 id="details-heading" className="sr-only">
        Portfolio Projects
      </h2>

      <div className="divide-y border-t">
        {portfolioItems.map((project) => (
          <Disclosure key={project.title} as="div">
            <h3>
              <DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-md font-medium text-gradient-primary group-hover:text-gradient-hover">
                          {project.title}
                        </span>
                <span className="ml-6 flex items-center">
                          <PlusIcon
                            aria-hidden="true"
                            className="block h-6 w-6 text-primary-two group-hover:text-primary-one group-data-[open]:hidden"
                          />
                          <MinusIcon
                            aria-hidden="true"
                            className="hidden h-6 w-6 text-primary-two group-hover:text-primary-one group-data-[open]:block"
                          />
                        </span>
              </DisclosureButton>
            </h3>
            <DisclosurePanel className="prose prose-sm pb-6 text-secondary text-sm">
              {project.content}
            </DisclosurePanel>
          </Disclosure>
        ))}
      </div>
    </section>
  )
}