import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { motion, AnimatePresence } from 'framer-motion';

export function SuccessAlert({ message, onClose }) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 1000); // Wait for the fade-out transition to complete before removing the alert
    }, 2000); // Show for 4 seconds before starting the fade-out
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="flex items-center p-4 mb-4 bg-green-100 rounded-lg"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }} // 1-second fade out
        >
          <CheckCircleIcon aria-hidden="true" className="flex-shrink-0 h-6 w-6 text-green-500 mr-3" />
          <p id="success-message" className="text-sm text-green-600">
            {message}
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export function ErrorAlert({ message }) {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="flex items-center p-4 mb-4 bg-red-100 rounded-lg relative">
      <ExclamationCircleIcon aria-hidden="true" className="flex-shrink-0 h-6 w-6 text-red-500 mr-3" />
      <p id="root-error" className="text-sm text-red-600">
        {message}
      </p>
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-1 right-1 text-red-500 hover:text-red-700"
      >
        <XMarkIcon aria-hidden="true" className="h-5 w-5" />
      </button>
    </div>
  );
}