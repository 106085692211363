export default function Banner() {
  return (
    <div className="relative z-20 isolate flex items-center justify-center gap-x-6 overflow-hidden bg-background px-6 py-2.5 sm:px-3.5">
      <div
        aria-hidden="true"
        className="pointer-events-none absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
      <div
        aria-hidden="true"
        className="pointer-events-none absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
      >
        <div
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
      <div className="flex items-center gap-x-4">
        <p className="text-sm leading-6 text-custom-white text-center">
          <strong className="font-semibold block sm:inline">Launching November 2024</strong>
          <span className="hidden sm:inline">
            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
              <circle r={1} cx={1} cy={1} />
            </svg>
            Contact us now to join our consultation waitlist!
          </span>
        </p>
        <a
          href="mailto:irsik.solutions@gmail.com?subject=Consulting%20Request"
          className="inline-flex rounded-full bg-gradient-primary px-3.5 py-1 text-sm font-semibold text-black shadow-sm hover:bg-gradient-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Contact Us<span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  );
}
