// Benefits.js
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { useInViewAnimation } from '../../../../hooks/useInViewAnimation';
import { featureVariants } from './variants';
import { BottomLeftSectionPolygon } from '../../../../components/BackgroundPolygons';
import { AcademicCapIcon, BanknotesIcon, ForwardIcon, BriefcaseIcon } from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Cost Savings',
    description: 'Eliminate the need to hire an in-house team for model creation, significantly reducing operational costs.',
    icon: BanknotesIcon,
  },
  {
    name: 'Expert Guidance',
    description: 'Leverage our extensive experience in AI model development to ensure you receive cutting-edge, reliable models.',
    icon: AcademicCapIcon,
  },
  {
    name: 'Quick Deployment',
    description: 'Deploy your models faster with our streamlined process, allowing you to start benefiting from AI insights immediately.',
    icon: ForwardIcon,
  },
  {
    name: 'Focus on Core Business',
    description: 'Allow your team to concentrate on core business activities while we handle the intricacies of model development and deployment.',
    icon: BriefcaseIcon,
  },
];

export default function Benefits() {
  const ref = useRef();
  const controls = useInViewAnimation(ref);

  return (
    <div className="relative isolate">
      <div className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {/* ... */}
          <div ref={ref} className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature, index) => (
                <motion.div
                  key={feature.name}
                  variants={featureVariants}
                  initial="hidden"
                  animate={controls}
                  custom={index}
                  className="relative pl-16 lg:text-left"
                >
                  <dt className="text-base font-semibold leading-7 text-custom-white">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-accent">
                      <feature.icon aria-hidden="true" className="h-6 w-6 text-black" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-secondary">{feature.description}</dd>
                </motion.div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <BottomLeftSectionPolygon />
    </div>
  );
}
